import { AES, enc } from 'crypto-js'

const key = '331b3e56-74e1-4783-834f-4f4dc2e17a50'

/**
 * 数据加密
 * @param {string} data 数据
 * @returns {string} 密文
 */
export function encrypt (data) {
  return AES.encrypt(data, key).toString()
}

/**
 * 数据解密
 * @param {string} data 密文
 * @returns {string} 数据
 */
export function decrypt (data) {
  return AES.decrypt(data, key).toString(enc.Utf8)
}
