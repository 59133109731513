import { encrypt } from '@/utils/crypto'
// 判断是否为空
const isEmpty = (string) => {
  if (string === 'undefined' || string == null || string === '') {
    return true
  } else {
    return false
  }
}

// 判断是否有某项指定的权限
const jurisdictionVerify = (jurisdiction) => {
  var permissionsData = JSON.parse(localStorage.getItem('userInfo'))
  const index = permissionsData.permissions.indexOf(jurisdiction)
  return index !== -1
}
// 判断租户是否创建完成
const tenantIsComplete = () => {
  var permissionsData = JSON.parse(localStorage.getItem('userInfo'))
  const index = permissionsData.tenantIsComplete
  return index
}
// 判断是否有人员管理权限
const addressBookJurisdiction = () => {
  return jurisdictionVerify('Platform.AddressBook_Edit')
}
// 判断是否有邀请加入权限
const inviteJurisdiction = () => {
  return jurisdictionVerify('Platform.Invite')
}
// 判断是否有企业管理
const applicationJurisdiction = () => {
  return jurisdictionVerify('Platform.Application_Operation')
}
// 判断是否有企业信息权限
const enterpriseJurisdiction = () => {
  return jurisdictionVerify('Platform.Enterprise_Edit')
}
// 判断是否有权限查阅权限
const permissionReadJurisdiction = () => {
  return jurisdictionVerify('Platform.PlatformPermission_Read')
}
// 判断是否有权限分配权限
const permissionEditJurisdiction = () => {
  return jurisdictionVerify('Platform.PlatformPermission_Edit')
}
// 判断是否有角色增删改查的权限
const roleEditJurisdiction = () => {
  return jurisdictionVerify('AbpIdentity.Roles')
}

// 判断版本是否已到期
const VersionDue = () => {
  const tenantList = JSON.parse(localStorage.getItem('tenantList'))
  const expire = new Date(tenantList.expireTime).getTime()
  const now = new Date().getTime()
  return expire < now
}

// 非低代码平台应用的跳转url
const NotIbpsAppliationUrl = (url) => {
  const tenantId = localStorage.getItem('tenant')
  const token = localStorage.getItem('token')
  const obj = { tenantId: tenantId, token: token, source: 'pc' }
  let params = encrypt(JSON.stringify(obj))
  params = params.replaceAll('+', '%2B')
  url = url + '?params=' + params
  return url
}

export {
  NotIbpsAppliationUrl,
  isEmpty,
  addressBookJurisdiction,
  inviteJurisdiction,
  applicationJurisdiction,
  enterpriseJurisdiction,
  permissionReadJurisdiction,
  permissionEditJurisdiction,
  roleEditJurisdiction,
  tenantIsComplete,
  VersionDue,
}
